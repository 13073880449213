(function ($) {
  var $app = $('.gform_wrapper');
  var $select = $app.find('select');
  var $captcha = $app.find('.ginput_recaptcha');
  var $readOnly = $app.find('.gf_readonly input');

  $(window).on('load', function () {
    // Actions
    $app.on('focus blur', 'input, textarea, select', _activateField);
    $(document).on('gform_post_render', _reinit);
  
    // Callbacks
    _init();
  });

  var _init = function () {
    _addClassRecaptcha();
    _addReadOnly();
    // _hide_select_labels();
    _loopFields(_activateField);
  };

  var _reinit = function () {
    _loopFields(_activateField);
  };

  var _addClassRecaptcha = function () {
    $captcha.siblings('label').addClass('form-recaptcha-label');
  };

  var _hide_select_labels = function () {
    $select

      .closest('.gfield')

      .find('label')

      .hide();
  };

  var _addReadOnly = function () {
    $readOnly.attr('readonly', 'readonly');
  };

  var _loopFields = function (callback) {
    $app.find('input, select, textarea').each(function (event) {
      callback(event, this);
    });
  };

  var _activateField = function (event, target) {
    var $this;

    if (undefined !== target) {
      $this = $(target);
    } else {
      $this = $(this);
    }

    if ($this.parent().is('span')) {
      $this.closest('span').removeClass('used');
    } else {
      $this.closest('.gfield').removeClass('used');
    }

    if ('' !== $this.val() || event.type === 'focusin') {
      if ($this.parent().is('span')) {
        var $elem = $this.closest('span');
        $elem.addClass('used');
      } else {
        var $elem = $this.closest('.gfield');
        $elem.addClass('used');
      }
    }
  };
})(jQuery);
