(function($) {
  if ($(".lightbox-smg").length < 1) {
    return;
  }

  /** Define Variables */
  var $app = $(".lightbox-smg");

  /** Load Video Function */
  var _loadVideo = function(event) {
    event.preventDefault();
    $app.toggleClass("active");
    $lightVid = $(this).data("vid");
    $lightPhoto = $(this).data("lightbox");
    if ($lightVid) {
      $app
        .find(".lightbox-content")
        .html(
          '<div class="responsive-iframe"><iframe width="560" height="315" frameborder="0" allowfullscreen src="https://www.youtube.com/embed/' +
            $lightVid +
            '?rel=0&autoplay=1"></iframe></div>'
        );
    } else if ($lightPhoto) {
      $app.find(".lightbox-content").html("<img src='" + $lightPhoto + "' />");
    }
  };

  /** Stop Video Function */
  var _stopPlayer = function() {
    $app.toggleClass("active");
    $app.find("iframe").attr("src", "");
  };

  /** Triggers */
  $(".light-popup").on("click", _loadVideo);
  $app.on("click", ".overlay, .lightbox-close", _stopPlayer);
})(jQuery);
