// var lazyImageLoad = function() {
//   var lazyImages = document.querySelectorAll(".lazy");
//   var inAdvance = 300;
//   lazyImages.forEach(function(image) {
//     if (image.offsetParent == null) {
//       return;
//     }
//     if (image.classList.contains("loaded")) {
//       return;
//     }
//     if (image.offsetTop < window.innerHeight + window.pageYOffset + inAdvance) {
//       if (image.dataset.src != undefined) {
//         image.src = image.dataset.src;
//       }
//       if (image.dataset.bg != undefined) {
//         image.style.backgroundImage = "url(" + image.dataset.bg + ")";
//       }
//       image.onload = function() {
//         image.classList.add("loaded");
//       };
//     }
//   });
// };

// (function($) {
//   $(document).on("ready", lazyImageLoad);
//   window.addEventListener("scroll", lazyImageLoad);
//   window.addEventListener("resize", lazyImageLoad);
// })(jQuery);
