(function($) {
  if ($('#num-singles').length <= 0) return;
  var num = $('#num-singles').attr('value');
  var $noBlocks = $('.preview-nocontent-block');
  var size = window.innerWidth;
  var del;

  var _setSize = function() {
    size = window.innerWidth;
  };

  var _setDel = function() {
    del = 2;
    if (1800 <= size) {
      del = 3;
    }
    if (767 >= size) {
      del = num;
    }
  };

  var _setBlocks = function() {
    $noBlocks.hide();
    if (0 === num % del) return;
    for (var i = 0; i < del - (num % del); i++) {
      $noBlocks.eq(i).show();
    }
  };

  var _change = function() {
    _setSize();
    _setDel();
    _setBlocks();
  };

  $(window).on('load', _change);
  $(window).on('resize', _change);
})(jQuery);
