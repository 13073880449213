// (function($) {
//   var $schMeet =  $('.sch-meet');

//   var load_on_click = function(){

//      < !--ScheduleOnce embed START-- >
//       <div id="SOIDIV_TomMurphy" data-so-page="TomMurphy" data-height="550" data-style="border: 1px solid #d8d8d8; min-width: 290px; max-width: 900px;" data-psz="00"></div>

//       <!--ScheduleOnce embed END-- >
//   }
//   $schMeet.on('click', load_on_click);
//   // var _init = function() {
//   //   setTimeout(function() {
//   //     $("body").append(
//   //       "<script src='https://cdn.scheduleonce.com/mergedjs/so.js'></script>"
//   //     );

//   //     // Enable when testing and pushing to production
//   //     //
//   //     // $.getScript({
//   //     //   url: "https://cdn.scheduleonce.com/mergedjs/so.js",
//   //     //   cache: true
//   //     // });
//   //   }, 1500);
//   // };
//   // document.addEventListener("DOMContentLoaded", _init);
// })(jQuery);
