(function ($) {
  /**
   * Code to Hide/Show Header
   */
  var $body = $("body");
  var $header = $("#header");
  var $menu = $("#menu");
  // var $menu = $header.find('#header-menu');
  var lastScrollTop = 0;
  var mobileStopScroll = false;

  var testScroll = function () {
    if (mobileStopScroll) {
      return;
    }
    var st = $(window).scrollTop();
    if (st < lastScrollTop) {
      if ($header.hasClass("scroll")) {
        removeScroll();
      }
    } else if (st > 74) {
      if (!$header.hasClass("scroll")) {
        addScroll();
      }
    } else {
      removeScroll();
    }
    lastScrollTop = st;
  };

  var addScroll = function () {
    $header.addClass("scroll");
  };

  var removeScroll = function () {
    $header.removeClass("scroll");
  };

  var open_mobile_menu = function () {
    $(this)
      .closest("#menu")
      .toggleClass("open-menu");
    $(this)
      .find(".bars")
      .toggleClass("change");
    $("body, html").toggleClass("no-scroll");
    mobileStopScroll = mobileStopScroll ? false : true;
  };

  var activate_sub_menu = function () {
    var test = $(this).hasClass("dropdown-open");
    $(this)
      .closest("#menu-main-menu")
      .find(".dropdown-open")
      .removeClass("dropdown-open");
    if (!test) {
      $(this).addClass("dropdown-open");
      $(this)
        .siblings(".dropdown")
        .toggleClass("dropdown-open");
    }
  };

  $(window).on("scroll", testScroll);
  // if ($(window).width() < 1200) {
  //   $menu.find("#mobile-menu").on("click", open_mobile_menu);
  //   $menu.find(".dropdown-button").on("click", activate_sub_menu);
  // }




  var init_menu = function() {
    if ($(window).width() < 1200) {
      $menu.find("#mobile-menu").on("click", open_mobile_menu);
      $menu.find(".dropdown-button").on("click", activate_sub_menu);
    }
  }

  $(document).ready(init_menu)
  $(window).on('resize', init_menu)
  /**
   * code to schedule meeting popup
   */
  // $lightbox = $("#smg-lb");

  // var open_lightbox = function(event) {
  //   event.preventDefault();
  //   $lightbox.addClass("active");
  //   $lightbox
  //     .find("#smg-lb-cont")
  //     .html(
  //       '<script src="https://cdn.scheduleonce.com/mergedjs/so.js"></script><!--ScheduleOnce embed START--><div id="SOIDIV_TomMurphy" data-so-page="TomMurphy" data-height="550" data-style="border: 1px solid #d8d8d8; min-width: 290px; max-width: 900px;" data-psz="00"></div><!--ScheduleOnce embed END-->'
  //     );
  // };
  // var close_lightbox = function(event) {
  //   $lightbox.removeClass("active");
  //   $lightbox.find("#smg-lb-cont").html("");
  // };

  // $lightbox.on("click", close_lightbox);
  // $lightbox.find("#smg-close").on("click", close_lightbox);

  // $body.find(".sch-meet").on("click", open_lightbox);
})(jQuery);
