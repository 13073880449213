(function($) {
  /** Scroll to Div Via the Hash */
  var _initHashScroll = function() {
    var div = $(window.location.hash);
    if (div.length > 0) {
      _scrollToDiv(div);
    }
  };

  /** Helper Function: Scroll to Div */
  var _scrollToDiv = function(div) {
    $("html, body").animate(
      {
        scrollTop: div.offset().top
      },
      1000
    );
  };

  /** Scroll To The Next Section */
  var _scrollToHrefDiv = function(event) {
    event.preventDefault();
    var div = $(this).attr("href");
    div = $(div);
    if (div.length > 0) {
      _scrollToDiv(div);
    }
  };

  /** Scroll to Next Beaver Builder Row */
  var _scrollToNextRow = function() {
    $("html, body").animate(
      {
        scrollTop: $(this)
          .closest(".fl-row")
          .next(".fl-row")
          .offset().top
      },
      1000
    );
  };

  /** Triggers */
  $(window).on("load", _initHashScroll);
  $(".slight-next").on("click", _scrollToHrefDiv);
  $('a[href="#next"]').on("click", _scrollToNextRow);
  $('a[href="#next-no-animation"]').on("click", _scrollToNextRow);
})(jQuery);
