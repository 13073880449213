var lazyLoad = (function($) {
  var _lazyLoad = function(elem, num) {
    var $elem = $(".smg-lazy-load");
    var divider = 2; 

    // console.log(elem);

    if (typeof num === "number") {
      divider = num;
    }

    if (typeof elem === "string") {
      $elem = $(elem);
    }

    var height = window.innerHeight;
    var width = window.innerWidth;

    $elem.each(function() {
      if ($(this).hasClass("active")) {
        return true;
      }

      var rect = this.getBoundingClientRect();
      if (rect.top < height / divider) {
        // console.log(rect);
        $(this).addClass("active");
      }
    });
  };

  $(window).on("scroll", _lazyLoad);

  return {
    lazy: _lazyLoad
  };
})(jQuery);
